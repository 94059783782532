<template>
  <div>
    <service-bar></service-bar>
    <TopParallax
      title="CHURCH SERVICES"
      :img-src="imgSrc"
    />
    <service-info></service-info>
  </div>
</template>

<script>
import ServiceBar from '@/components/ServiceBar.vue'
import TopParallax from '@/components/TopParallax.vue'
import ServiceInfo from '@/components/ServiceInfo.vue'

export default {
  components: {
    TopParallax,
    ServiceBar,
    ServiceInfo
  },
  data() {
    return {
      imgSrc: require('@/assets/images/services_banner2.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Service'
      }
    }
  }
}
</script>
